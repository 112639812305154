import React, { useEffect, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';
import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import clsx from 'clsx';

const TermsAndCondition = () => {
  const intl = useIntl();
  const collapsiblesRef = useRef(null);

  useEffect(() => {
    bulmaCollapsible.attach(".is-collapsible", {
      container: collapsiblesRef.current,
    });
  }, []);

  const isDeLang = () => intl.locale === 'de';

  const customClassName = 'is-dark';


  return (
    <Layout
      section={'payment'}
      bannerTitle={intl.formatMessage({
        id: 'footer_faq',
        defaultMessage: 'FAQ',
      })}
    >
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                <div className="content px-6">
                  <div ref={collapsiblesRef} id="accordion_first">
                    {isDeLang() ? (
                      <>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>1. <a href="#collapsible-message-accordion-1" data-action="collapse">Warum Vienna Event Tickets?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-1" class="message-body is-collapsible is-active" data-parent="accordion_first">
                            <div class="message-body-content">
                              Willkommen bei Vienna Event Tickets! Wir sind Ihr Kartenbüro mit Sitz in Wien, der Welthauptstadt der klassischen Musik.
                              Vienna Event Tickets bietet Ihnen Eintrittskarten für alle klassische Konzerte im Herzen Wiens und Salzburg zum Original-Preis an, dadurch ersparen wir Ihnen viel Zeit und auch Geld!
                              Die ausgewählten Konzerte zeichnen sich nicht nur durch ihre bewundernswerte Musik der Klassiker aus, sondern auch durch ihre bezaubernde Ambiente im Herzen Wiens.
                              Wir offerieren eine sichere und schnelle Suche klassischer Konzerte von höchster Qualität ohne (versteckte) Extrakosten.
                              Zudem sind wir für Sie einfach und jederzeit erreichbar. Unsere Kunden stehen bei uns immer an oberster Stelle!
                              Und sollten Sie nach einem klassischen Konzert suchen, das nicht auf <a target="_blank" href="https://viennaeventtickets.com">viennaeventtickets.com</a> zu finden ist: kein Problem!<br />
                              Lassen Sie uns Ihre Wünsche erfüllen!
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>2. <a href="#collapsible-message-accordion-2" data-action="collapse">Wie kann ich ein Ticket buchen?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-2" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              So buchen Sie Ihre Tickets einfach und sicher:<br />
                              1. Anzahl an Karten in der gewünschten Kategorie am gewünschten Tag auswählen.<br />
                              2. Name und Mailadresse (an welche die Buchungsbestätigung gesendet werden soll) angeben und auf <strong>“Buchung bezahlen”</strong> klicken. <br />
                              3. Sie werden auf die Seite unseres Zahlungsanbieters weitergeleitet. Dort überprüfen Sie Ihre Bestellung und schließen die Zahlung per Kreditkarte ab.<br />
                              4. Sie erhalten nun Ihre Buchungsbestätigung per E Mail an die von Ihnen angegebene Emailadresse. <br />
                              5. Vor dem Konzert zeigen Sie die Buchungsbestätigung (ausgedruckt oder einfach am Smartphone) an der Abendkassa und erhalten Ihre Eintrittskarten.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>3. <a href="#collapsible-message-accordion-3" data-action="collapse">Welche Kreditkarten werden akzeptiert?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-3" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Sie können mit allen gängigen Kreditkarten bezahlen, wie z.B. <em>Visa</em>, <em>MasterCard</em> und <em>American Express.</em>
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>4. <a href="#collapsible-message-accordion-4" data-action="collapse">Wieso kann ich keinen Sitzplatz auswählen?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-4" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Die Sitzplatzwahl bei den meisten klassischen Konzerten in Wien funktioniert anders als Sie es vielleicht gewohnt sind. Sie buchen ein Ticket innerhalb einer Kategorie, aber keinen fixen Sitzplatz.<br />
                              Die meisten historischen Säle in Wien haben keine fixe Bestuhlung und daher auch keine nummerierten Sitze.<br />
                              Die meisten Abenddienstpersonal führt Sie zu Ihren Sitzplätzen. Sollten Sie mit mehreren Personen zusammen gebucht haben können Sie in der Regel alle zusammen sitzen.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>5. <a href="#collapsible-message-accordion-5" data-action="collapse">Ich habe mein Ticket bei Vienna Event Tickets gebucht, aber noch keine Bestellbestätigung erhalten. Wie erhalte ich diese?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-5" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Sobald Sie Ihre Tickets online gebucht haben, wird Ihre Bestellung durch uns bestätigt, und zwar erhalten Sie eine E-Mail mit Ihrem Buchungsbeleg. Dies ist Ihre Bestellbestätigung. <br />
                              Gleichzeitig ist es auch ihr E-Ticket, das Sie bitte ausgedruckt mitbringen oder auf Ihr Smartphone/ Tablet downloaden, sodass Sie dieses für den Eintritt am Tag der Veranstaltung vorweisen können.<br /><br />
                              Falls Sie einige Minuten nach Ihrer Bestellung keine E-Mail erhalten sollten: bitte vergessen Sie nicht, Ihren Spam-Folder zu überprüfen, eventuell ist unsere E-Mail dort gelandet.<br /><br />
                              Um allfällige andere Ursachen abzuklären (z.B.: Tippfehler bei Ihrer Mailadresse), kontaktieren Sie uns einfach per E-Mail: <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a> oder telefonisch unter: <a href="tel:+43 67 67 98 4066">+43 6767984066</a>, unter Angabe Ihrer Kontaktdaten und der von Ihnen gebuchten Veranstaltung.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>6. <a href="#collapsible-message-accordion-6" data-action="collapse">Was passiert wenn ein Konzert abgesagt wird?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-6" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Sollte ein Konzert abgesagt werden, informieren wir Sie umgehend. Dann haben Sie die Wahl sich entweder für einen Ersatztermin zu entscheiden oder die Kosten für die Tickets <em>zurückerstattet zu bekommen</em>.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>7. <a href="#collapsible-message-accordion-7" data-action="collapse">Kann ich meine Buchung wieder stornieren?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-7" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Sie können Ihr Ticket <strong>14 Tage</strong> vor dem Tag des Konzertes jederzeit stornieren und bekommen die gesamten Kosten für die Tickets erstattet. <br />
                              Danach wird eine Stornogebühr von <em>50%</em> bis <em>48 Stunden</em> vor dem Konzert erhoben. Bei einer Stornierung <em>48 Stunden</em> vor dem Konzert können leider keine Kosten zurückerstattet werden.<br /><br />
                              Alle Stornierungen müssen schriftlich an <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a> erfolgen.
                            </div>
                          </div>
                        </article>
                      </>
                    ) : (
                      <>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>1. <a href="#collapsible-message-accordion-1" data-action="collapse">Why Vienna Event Tickets?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-1" class="message-body is-collapsible is-active" data-parent="accordion_first">
                            <div class="message-body-content">
                              Welcome to Vienna Event Tickets! We are your ticket office based in Vienna, the world capital of classical music.
                              Vienna Event Tickets offers you tickets for all classical concerts in the heart of Vienna and Salzburg at the original price in order to save you a lot of time and money.
                              The selected classical concerts displayed on our website are distinguished not only by their admirable music of the classics, but also by their enchanting ambience in the heart of Vienna.
                              We offer you an easy and fast search of classical concerts of the highest quality without any (hidden) extra costs.
                              Moreover, we are easy to reach for you at any time. Our customers are always our top priority! And if you are looking for an event that you cannot find on <a target="_blank" href="https://viennaeventtickets.com">viennaeventtickets.com</a>: no problem!<br />
                              Contact us and let us fulfill your wishes!
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>2. <a href="#collapsible-message-accordion-2" data-action="collapse">How can I book a ticket?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-2" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              How to book your tickets easily and securely:<br />
                              1. Select the number of tickets in the desired category on the desired day.<br />
                              2. Enter your name and email address (to which the booking confirmation should be sent) and click on <strong>“Proceed to payment”</strong>. <br />
                              3. You will be redirected to our payment provider. There you can review the order and complete payment by credit card. <br />
                              4. You will now receive your booking confirmation by email to the email address you provided. <br />
                              5. Before the concert, show the booking confirmation (printed out or simply on your smartphone) at the box office and receive your tickets.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>3. <a href="#collapsible-message-accordion-3" data-action="collapse">Which credit cards are accepted?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-3" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              You can pay with all major credit cards, such as <em>Visa</em>, <em>MasterCard</em> and <em>American Express.</em>
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>4. <a href="#collapsible-message-accordion-4" data-action="collapse">Why can't I choose a seat?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-4" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              Seating at most classical concerts in Vienna works differently than you may be used to. You book a ticket within a category and not a seat with a number.<br />
                              Most of the historical halls in Vienna have no fixed seating and therefore no numbered seats.<br />
                              The most evening service staff will guide you to your seats. If you have booked together with several people, you can usually all sit together.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>5. <a href="#collapsible-message-accordion-5" data-action="collapse">I've booked my ticket from Vienna Event Tickets, but haven't received any confirmation of my order. Was my booking successful?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-5" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              As soon as you have booked your tickets online, your order will be confirmed by us. You will receive an e-mail with your booking voucher, this is your order confirmation. <br />
                              Please print it out or download it on your smartphone/tablet to show it for admission on the day of the concert.<br /><br />
                              If you do not receive an e-mail after your booking: please do not forget to check your spam folder, maybe our e-mail got caught there.<br /><br />
                              To clarify any other causes (e.g.: typing errors in your e-mail address), just contact us by e-mail at <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a> or by phone at <a href="tel:+43 67 67 98 4066">+43 6767984066</a>, stating your contact details and the event you have booked.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>6. <a href="#collapsible-message-accordion-6" data-action="collapse">What happens if a concert is canceled?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-6" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              If a concert is canceled, we will inform you immediately. You will then have the choice of either choosing an alternative date or of having the cost of the tickets <em>refunded</em>.
                            </div>
                          </div>
                        </article>
                        <article class={clsx('message', customClassName)}>
                          <div class="message-header">
                            <p>7. <a href="#collapsible-message-accordion-7" data-action="collapse">Can I Cancel My Booking?</a></p>
                          </div>
                          <div id="collapsible-message-accordion-7" class="message-body is-collapsible" data-parent="accordion_first">
                            <div class="message-body-content">
                              You can cancel your ticket at any time <strong>14 days</strong> before the day of the concert and the entire cost of the tickets will be reimbursed.<br />
                              Thereafter, a cancellation fee of <em>50%</em> will be charged until <em>48 hours</em> before the concert. In case of a cancellation <em>48 hours</em> before the concert, unfortunately, no refund can be made.<br /><br />
                              All cancellations must be made in writing to <a href="mailto:office@viennaeventtickets.com">office@viennaeventtickets.com</a>.
                            </div>
                          </div>
                        </article>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </section >
    </Layout >
  );
};

export default TermsAndCondition;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.footer_faq}
    pathname={location.pathname}
  />
);